import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { useMiscBandSignupReferralStore } from '~/stores/miscBandSignupReferral'

import { provideGetWalletReferralPromo } from '~/api-core/Wallet/FetchReferralPromo'

export function useSyncReferralCode() {
  const { coreFetch } = useProvideCoreFetch()
  const { $pinia } = useNuxtApp()
  const miscBandSignupReferralStore = useMiscBandSignupReferralStore($pinia)
  const userStore = useUserStore($pinia)
  const REFERRAL_CODE_BACKUP_COOKIE = 'referralCodeBackup'
  const cookieContext = useCookie(REFERRAL_CODE_BACKUP_COOKIE)

  /**
   * Sync referral code with miscBandSignupReferralStore and cookie, or fetch it from the server
   */
  async function syncReferralCode() {
    if (miscBandSignupReferralStore.code.length)
      cookieContext.value = miscBandSignupReferralStore.code

    // if cookie use it
    if (cookieContext.value) {
      miscBandSignupReferralStore.SET_CODE(cookieContext.value)
      return
    }

    // otherwise fetch from the server and set it
    const fetchWalletReferralPromo = provideGetWalletReferralPromo(coreFetch)
    const { code } = await fetchWalletReferralPromo().catch(() => ({
      code: '',
    }))
    setCode(code)
  }

  const userIsEligibleToReferralPromo = computed(() => {
    return userStore.IS_NEW || !userStore.IS_LOGGED_IN
  })

  function setCode(newCode: string) {
    if (userIsEligibleToReferralPromo.value) {
      miscBandSignupReferralStore.SET_CODE(newCode)

      if (newCode.length) syncReferralCode()
      else cookieContext.value = null
    } else {
      cookieContext.value = null
      miscBandSignupReferralStore.SET_CODE('')
    }
  }

  return {
    syncReferralCode,
    userIsEligibleToReferralPromo,
  } as const
}
